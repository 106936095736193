<template>
  <div class="pbf">
     <BrandTabGrowers
        v-if="type === 'brands'"
        :inurl="`/${props.type}/${props.id}/growers`"
        :brand-id="props.id"
      />
      <BrandTabGrowers
          v-else
          :inurl="`/${props.type}/${props.id}/growers`"
          :product-id="props.id"
        />
  </div>
</template>


<script setup lang="ts">

interface Props {
  id: number
  type: string
}

const props = defineProps<Props>()

</script>